/* 每个页面公共css */
@import url("./assets/less/style-mirror.css");
.container_night {
  background-color: #333333;
}
.container_night .el-main {
  background-color: #333333;
}
.container_night .CodeMirror {
  caret-color: gray;
  color: gray;
  background-color: #1e1e1e;
  box-shadow: inset 0 0 0 1px rgba(100, 37, 37, 0.102);
}
.container_night .output_night .preview {
  background-color: #1e1e1e;
  box-shadow: 0 0 70px rgba(0, 0, 0, 0.3);
}
.container_night .output_night .preview-wrapper {
  background-color: #1e1e1e;
  box-shadow: inset 0 0 0 1px rgba(233, 231, 231, 0.102);
}
.container_night .output_night .code-snippet__fix {
  background-color: #eeeeee;
}
.container_night .cm-s-style-mirror .CodeMirror-matchingbracket {
  color: #f0f0f0 !important;
  background: #1e1e1e !important;
}
.container_night .cm-s-xq-light span.cm-variable-2,
.container_night .cm-s-style-mirror span.cm-tag {
  color: gray;
}
.container_night .cm-s-xq-light .CodeMirror-activeline-background {
  background-color: transparent;
}
.container_night .cm-s-xq-light span.cm-string {
  color: #8e9eb9;
}
.container_night .cm-s-xq-light span.cm-link {
  color: #84868b;
}
.container_night .editor__header {
  background-color: #3c3c3c;
}
.container_night .el-button {
  color: #f0f0f0;
  background-color: #1e1e1e;
  border: 1px solid transparent;
}
.container_night .el-button.is-plain:focus,
.container_night .el-button.is-plain:hover {
  background: #1e1e1e;
  color: #f0f0f0;
  border: 1px solid #f0f0f0;
}
.container_night .el-button.is-plain:focus i,
.container_night .el-button.is-plain:hover i {
  color: #f0f0f0;
}
.container_night .insert__dialog .el-dialog,
.container_night .about__dialog .el-dialog,
.container_night .reset__dialog .el-dialog,
.container_night .upload__dialog .el-dialog {
  background-color: #333333;
}
.container_night .insert__dialog .el-dialog__body,
.container_night .about__dialog .el-dialog__body,
.container_night .reset__dialog .el-dialog__body,
.container_night .upload__dialog .el-dialog__body {
  color: #f0f0f0;
}
.container_night .insert__dialog .el-dialog__title,
.container_night .about__dialog .el-dialog__title,
.container_night .reset__dialog .el-dialog__title,
.container_night .upload__dialog .el-dialog__title,
.container_night .insert__dialog .el-form-item__label,
.container_night .about__dialog .el-form-item__label,
.container_night .reset__dialog .el-form-item__label,
.container_night .upload__dialog .el-form-item__label {
  color: #f0f0f0;
}
.container_night .insert__dialog .el-tabs__item,
.container_night .about__dialog .el-tabs__item,
.container_night .reset__dialog .el-tabs__item,
.container_night .upload__dialog .el-tabs__item {
  color: gray;
}
.container_night .insert__dialog .el-tabs__nav-wrap::after,
.container_night .about__dialog .el-tabs__nav-wrap::after,
.container_night .reset__dialog .el-tabs__nav-wrap::after,
.container_night .upload__dialog .el-tabs__nav-wrap::after {
  background-color: #84868b;
}
.container_night .insert__dialog .is-active,
.container_night .about__dialog .is-active,
.container_night .reset__dialog .is-active,
.container_night .upload__dialog .is-active {
  color: #f0f0f0;
}
.container_night .insert__dialog .el-upload-dragger,
.container_night .about__dialog .el-upload-dragger,
.container_night .reset__dialog .el-upload-dragger,
.container_night .upload__dialog .el-upload-dragger {
  background-color: #1e1e1e;
}
.container_night ::v-deep .el-icon-upload,
.container_night .el-icon-download,
.container_night .el-icon-refresh,
.container_night .el-icon-s-grid,
.container_night .el-icon-document {
  color: #f0f0f0;
}
.container_night ::-webkit-scrollbar {
  background-color: #1e1e1e;
}
.CodeMirror {
  padding-bottom: 0;
  height: 100% !important;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  font-family: "PingFang SC", BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif !important;
}
.CodeMirror-vscrollbar:focus {
  outline: none;
}
.CodeMirror-scroll {
  padding: 0 20px;
  overflow-x: hidden !important;
  overflow-y: scroll !important;
}
.CodeMirror-vscrollbar {
  width: 0px;
  height: 0px;
}
.CodeMirror-wrap {
  padding-top: 20px;
  padding-bottom: 20px;
  box-sizing: border-box;
}
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: #fff;
}
::-webkit-scrollbar-track {
  border-radius: 6px;
  background-color: rgba(200, 200, 200, 0.3);
}
::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background-color: rgba(144, 146, 152, 0.5);
  transition: background-color 0.3s;
}
::-webkit-scrollbar-thumb:hover {
  background-color: rgba(144, 146, 152, 0.5);
}
/* CSS-hints */
.CodeMirror-hints {
  position: absolute;
  z-index: 10;
  overflow: hidden;
  list-style: none;
  margin: 0;
  padding: 2px;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.08);
  min-width: 200px;
  font-size: 12px;
  font-family: monospace;
  max-height: 20em;
  overflow-y: auto;
  color: #333333;
}
.CodeMirror-hint {
  margin: 0;
  margin-top: 10px;
  padding: 4px 6px;
  border-radius: 2px;
  white-space: pre;
  color: black;
  cursor: pointer;
}
.CodeMirror-hint:first-of-type {
  margin-top: 0;
}
.CodeMirror-hint:hover {
  background: #f0f0f0;
}
