















































































































































































































































































































































































































































































































































































/deep/ .el-dialog {
  width: 55%;
  min-height: 615px;
  min-width: 640px;
}
/deep/ .el-upload-dragger {
  display: flex;
  flex-flow: column;
  justify-content: center;
  width: 500px;
  height: 360px;
  .el-icon-upload {
    margin-top: 0;
  }
}
/deep/ .el-dialog__body {
  padding-bottom: 50px;
}
.upload-panel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  .el-select {
    align-self: flex-end;
    margin: 0 67.75px 20px;
    width: 100px;
  }
}

.github-panel {
  display: flex;
  justify-content: center;
  &.formCustom {
    width: 100%;
  }
  .formCustomElInput {
    /deep/ .CodeMirror {
      border: 1px solid #eee;
      height: 300px !important;
      font-family: "Fira Mono", "DejaVu Sans Mono", Menlo, Consolas, "Liberation Mono", Monaco, "Lucida Console", monospace !important;
      line-height: 20px;
      .CodeMirror-scroll {
        padding: 10px;
      }
    }
  }
}

.setting-form {
  width: 100%;

  .el-form-item {
    margin: 15px;
  }

  .el-form-item:last-child {
    text-align: right;
  }
}
