








































































































/deep/ .el-dialog {
  width: 55%;
  min-height: 375px;
  min-width: 440px;
}
.tb-options {
  margin-bottom: 20px;
}

.input-table ::v-deep .el-input__inner {
  border-radius: 0;
}

.head-style /deep/ .el-input__inner {
  background-color: #f2f2f2;
}
