





































/deep/ .el-dialog {
  min-width: 440px;
}
.reset__dialog {
  text-align: center;
}

.text {
  text-align: center;
}

.dialog-footer {
  text-align: center;
}
